import {
  Container,
  Text,
  Button,
  HStack,
  Box,
  VStack,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  Divider,
  Flex,
  Input,
  FormControl,
  Select,
  Checkbox,
  FormLabel,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import NavBar from "../components/header";
import { SEO } from "../components/seo";
import { InputField } from "../components/forms/InputField";
import Footer from "../components/footer";
import { useRef, useState } from "react";
import { Form } from "../components/forms/Form";
import * as z from "zod";
import {
  Checkout,
  CouponCode,
  useValidateCoupon,
} from "../services/api/payment";
import toast from "react-hot-toast";
import { usaStates } from "typed-usa-states";
import { navigate } from "gatsby";
import {
  BidBoxConfig,
  OrderData,
  placeOrder,
  PlanCoverage,
  WarrantyPlan,
} from "../services/api/bidbox";
import { TextAreaField } from "../components/forms/TextareaField";
import Autocomplete from "react-google-autocomplete";
import { AuthorizeNet } from "../lib/authorizeNet";

const couponSchema = z.object({
  code: z.string().min(1, "Please input correct coupon"),
});

const authNetLoader = new AuthorizeNet(BidBoxConfig.AUTHNET_PROD);
setTimeout(() => {
  authNetLoader.loadScript();
}, 2000);

let masterSchema = z.object({});
const baseSchema = z.object({
  first_name: z.string().min(1, "First name is required"),
  last_name: z.string().min(1, "Last name is required"),
  // bill_street: z.string().min(1, "Street address is required"),
  // bill_apartment: z.string().optional(),
  // bill_city: z.string().min(1, "City is required"),
  // bill_state: z.string().min(1, "State is required"),
  // bill_zip: z.string().min(1, "ZIP is required"),
  phone: z.string().min(1, "Phone is required"),
  email: z.string().email(),
  // warranty_street: z.string().min(1, "Street address is required"),
  // warranty_city: z.string().min(1, "City is required"),
  // warranty_state: z.string().min(1, "State is required"),
  // warranty_zip: z.string().min(1, "ZIP is required"),
});

const buyerAgentSchema = z.object({
  buyer_agent_name: z.string().min(1, "Buyer Agent Name is required"),
  buyer_agent_email: z.string().min(1, "Buyer Agent email is required"),
  buyer_agent_phone: z.string().min(1, "Buyer Agent Phone is required"),
  buyer_agent_brokerage: z.string().min(1, "Buyer Brokerage is required"),
});
const sellerAgentSchema = z.object({
  seller_agent_name: z.string().min(1, "Seller Agent Name is required"),
  seller_agent_email: z.string().min(1, "Seller Agent email is required"),
  seller_agent_phone: z.string().min(1, "Seller Agent Phone is required"),
  seller_agent_brokerage: z.string().min(1, "Seller Brokerage is required"),
});
const lenderSchema = z.object({
  lender_name: z.string().min(1, "Lender Name is required"),
  lender_email: z.string().min(1, "Lender Email is required"),
  lender_phone: z.string().min(1, "Lender Phone is required"),
  lender_company: z.string().min(1, "Lender Agency is required"),
});
const titleSchema = z.object({
  title_name: z.string().min(1, "Title/Escrow Name is required"),
  title_email: z.string().min(1, "Title/Escrow Email is required"),
  title_phone: z.string().min(1, "Title/Escrow Phone is required"),
  title_company: z.string().min(1, "Title/Escrow Agency is required"),
});
const tcSchema = z.object({
  tc_name: z.string().min(1, "TC Name is required"),
  tc_email: z.string().min(1, "TC Email is required"),
  tc_phone: z.string().min(1, "TC Phone is required"),
  tc_company: z.string().min(1, "TC Agency is required"),
});

const cleanPhone = (dirty?: string) => {
  if (dirty === null || dirty === undefined) {
    return null;
  }
  if (typeof dirty === "number") {
    return dirty;
  }
  if (dirty === "") {
    return null;
  }

  let clean = dirty.replace(/\D/g, "");

  if (clean[0] === "1" && clean.substring(0, 3) !== "111") {
    clean = clean.substring(1);
  }

  //  capped at 10 digits
  clean = clean.substring(0, 10);
  return parseInt(clean, 10);
};

// Returns an array of objects, of format:
// [  { coverage_id: xxx,
//      qty: yyy
//    }, ...
// ]
const getOptionsObjectForSaving = (options: Array<PlanCoverage>): string => {
  const options_array: Array<{ coverage_id: number; qty: number }> = [];
  for (const o of options) {
    // Skip if it's a policy. We only want to process options here.
    if ((o.qty ?? 0) > 0 && String(o.id).includes("policy-") == false) {
      const option_obj = {
        coverage_id: o.id,
        qty: o.qty,
      };
      options_array.push(option_obj);
    }
  }
  return options_array;
}

const CheckoutPage = (props: {
  location: {
    state: {
      isAnnual: boolean;
      totalPrice: number;
      isHomeownerOrder?: boolean;
      carts: PlanCoverage[];
      policy: WarrantyPlan;
      multipleYears?: number;
      under6K: boolean;
      agentType: string;
    };
  };
}) => {
  const [isLoading, setLoading] = useState(false);

  // Default-enable Buyer's Agent box for Buyer Agent and Buyer txns; ditto Seller's Agent box for Listing Agent txns and Escrow for Escrow txns.
  // Other txns get no boxes enabled by default, for now.
  const { agentType } = props.location.state || {};
  const [isBuyerAgentEnabled, setBuyerAgentEnabled] = useState(
    true /* := always on, per Amanda */ ||
      agentType == "buyer_agent" ||
      agentType == "buyer"
  );
  const [isSellerAgentEnabled, setSellerAgentEnabled] = useState(
    agentType == "listing_agent"
  );
  const [isEscrowEnabled, setEscrowEnabled] = useState(
    agentType == "escrow_officer"
  );

  const [isLenderEnabled, setLenderEnabled] = useState(false);
  const [isTCEnabled, setTCEnabled] = useState(false);
  const secretPath = ["ArrowUp", "ArrowLeft", "ArrowDown", "ArrowRight"];
  let orderRef = useRef(null);
  const [orderValid, setOrderValid] = useState(false);
  const [ccError, setCCError] = useState("");
  let orderButtonRef = useRef(null);

  let nonce_desc: string | undefined;
  let nonce_val: string | undefined;
  let billing_first: string | undefined;
  let billing_last: string | undefined;

  let isTest = false;
  let secretNdx = 0;
  let requriesCC = false;
  let ccResponse: any;

  // document.onkeydown = (evt) => {
  //     if (evt.ctrlKey && evt.key === secretPath[secretNdx]) {
  //         secretNdx += 1;
  //         if (secretNdx >= secretPath.length) {
  //             console.log("Loaded Test");
  //             isTest = true;
  //             secretNdx = 0;
  //         }
  //     } else {
  //         secretNdx = 0;
  //     }
  // };

  const [warrantyInfo, setWarranty] = useState({
    address: "",
    city: "",
    zip: "",
    state: "",
    lat: 0,
    lon: 0,
    place_id: "",
  });

  //
  // useEffect(() => {
  //     window.authnetresp = (response) => {
  //         console.log("Response:", response);
  //         if (formRef.current) {
  //             console.log("Submitting...");
  //             formRef.current.submit();
  //         } else {
  //             console.log("NOT Submitting Authnet");
  //         }
  //     }
  // }, []);

  const creditCardResp = (response: any) => {
    ccResponse = response;
    if (response.messages.resultCode != "Ok") {
      alert("FAILED TO COLLECT CREDIT CARD, Try Again");
      return;
    }
    //  gather CC info
    billing_first = response.customerInformation.firstName;
    billing_last = response.customerInformation.lastName;
    nonce_desc = response.opaqueData.dataDescriptor;
    nonce_val = response.opaqueData.dataValue;
    requriesCC = true;

    if (orderRef.current) {
      console.log("Submitting...");
      orderButtonRef.current.click();
    }
  };
  authNetLoader.cb = creditCardResp;

  const manualCC = () => {
    // if (orderRef.current) {
    //     console.log("Submitting...");
    //     orderButtonRef.current.click();
    // }
    // return;
    creditCardResp({
      opaqueData: {
        dataDescriptor: "COMMON.ACCEPT.INAPP.PAYMENT",
        dataValue:
          "eyJjb2RlIjoiNTBfMl8wNjAwMDUyMzkyNzU4QkNFREJBN0Q2MTc0NDM1QzUxNkI5RDM0QTM3MzUxOUNGMjQzQjg0NjA1NUQ1MjhFQzc0NjI4RUVGMEVBQkU3RUJCNDkxRDM1MDAwQTkxRDI0QzNCMUFEODQ2IiwidG9rZW4iOiI5Njk2NTU3MDMyNTAxNzQ0NjA0NjAxIiwidiI6IjEuMSJ9",
      },
      messages: {
        resultCode: "Ok",
        message: [
          {
            code: "I_WC_01",
            text: "Successful.",
          },
        ],
      },
      encryptedCardData: {
        cardNumber: "XXXXXXXXXXXX1111",
        expDate: "11/23",
        bin: "411111",
      },
      customerInformation: {
        firstName: "123",
        lastName: "123",
      },
    });
  };

  const validateForm = (event) => {
    if (orderRef.current && orderRef.current.checkValidity()) {
      setOrderValid(true);
    } else {
      setOrderValid(false);
    }
  };

  const rebuildSchema = () => {
    if (isHomeownerOrder) {
      masterSchema = baseSchema;
    } else {
      masterSchema = baseSchema.merge(
        z.object({
          order_notes: z.string().optional(),
        })
      );
    }
    if (isBuyerAgentEnabled) {
      masterSchema = masterSchema.merge(buyerAgentSchema);
    }
    if (isSellerAgentEnabled) {
      masterSchema = masterSchema.merge(sellerAgentSchema);
    }
    if (isLenderEnabled) {
      masterSchema = masterSchema.merge(lenderSchema);
    }
    if (isEscrowEnabled) {
      masterSchema = masterSchema.merge(titleSchema);
    }
    if (isTCEnabled) {
      masterSchema = masterSchema.merge(tcSchema);
    }
  };
  const couponValidMutation = useValidateCoupon();

  const {
    isAnnual,
    totalPrice = 0,
    carts = [],
    policy,
    isHomeownerOrder: isHomeownerOrder,
    multipleYears,
    under6K,
  } = props.location.state || {};
  const affiliateOrderH = isHomeownerOrder ? 0 : 30;
  const ref = React.useRef(null);
  rebuildSchema();

  // React.useEffect(() => {
  //     if (!totalPrice) navigate(-1);
  // }, [])

  const onCancel = () => {
    navigate(-1);
    toast.success("Order is cancelled.");
  };

  const onSubmitCoupon = async (values: CouponCode) => {
    values.products = carts;
    values.isAnnual = isAnnual;
    const coupon = await couponValidMutation.mutateAsync(values);
    if (coupon && couponValidMutation.isSuccess) {
      navigate(-1);
      toast.success("Coupon applied successfully");
      return;
    }
    if (couponValidMutation.isSuccess && !coupon) {
      toast.error("Coupon is invalid");
    }
  };

  const onSubmitOrder = async (values: Checkout) => {
    setCCError("");
    try {
      setLoading(true);
      let orderData: OrderData = {
        policy_id: policy.id,
        is_monthly: !isAnnual,
        is_affiliate_price: !isHomeownerOrder,
        payment_type: isHomeownerOrder ? "Check" : "Escrow",
        tax_rate: 0,
        type: isHomeownerOrder ? "Direct" : "Closing",
        who_placed: isHomeownerOrder ? "HomeOwner" : "Affiliate",
        name: values.first_name + " " + values.last_name,
        email: values.email,
        phone: cleanPhone(values.phone) as any,
        origin: "Website",
        street: warrantyInfo.address,
        city: warrantyInfo.city,
        zip: Number(warrantyInfo.zip),
        state: warrantyInfo.state,
        lat: warrantyInfo.lat,
        lon: warrantyInfo.lon,
      };
      if (isTest) {
        orderData.name = "Seller One";
        orderData.email = "seller1@bidboxpro.com";
        orderData.phone = 1112080401;
        orderData.street = "765 Main St";
        orderData.city = "meridian";
        orderData.zip = 83646;
        orderData.state = "ID";
        orderData.lat = 43.6117431;
        orderData.lon = -116.1964154;
      }

      if (nonce_desc) {
        orderData.nonce_desc = nonce_desc;
        orderData.nonce_val = nonce_val;
        orderData.billto_first = billing_first;
        orderData.billto_last = billing_last;
        orderData.payment_type = "CreditCard";
      }
      orderData.note = "";
      if (!isHomeownerOrder) {
        if (isBuyerAgentEnabled) {
          orderData.buyer_agent_name = values.buyer_agent_name;
          orderData.buyer_agent_email = values.buyer_agent_email;
          orderData.buyer_agent_phone = cleanPhone(
            values.buyer_agent_phone
          ) as any;
          orderData.buyer_agent_brokerage = values.buyer_agent_brokerage;
        }
        if (isSellerAgentEnabled) {
          orderData.seller_agent_name = values.seller_agent_name;
          orderData.seller_agent_email = values.seller_agent_email;
          orderData.seller_agent_phone = cleanPhone(
            values.seller_agent_phone
          ) as any;
          orderData.seller_agent_brokerage = values.seller_agent_brokerage;
        }
        if (isEscrowEnabled) {
          orderData.title_company = values.title_company;
          orderData.title_email = values.title_email;
          orderData.title_phone = cleanPhone(values.title_phone) as any;
          orderData.title_name = values.title_name;
        }
        if (isLenderEnabled) {
          orderData.lender_company = values.lender_company;
          orderData.lender_email = values.lender_email;
          orderData.lender_phone = cleanPhone(values.lender_phone) as any;
          orderData.lender_name = values.lender_name;
        }
        if (isTCEnabled) {
          orderData.tc_company = values.tc_company;
          orderData.tc_email = values.tc_email;
          orderData.tc_phone = cleanPhone(values.tc_phone) as any;
          orderData.tc_name = values.tc_name;
        }
        orderData.note = values.order_notes;
      }

      orderData.options = getOptionsObjectForSaving(carts);

      const ret = await placeOrder(orderData);
      if (typeof ret === "string") {
        setCCError(
          `Failed to process Order[${ret}]  Try again or call Acclaimed at: 888.494.9460`
        );
        setLoading(false);
      } else if (ret) {
        toast.success("Successfully placed order");
        console.log("Order SUCCESS!");
        navigate("/complete");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error while placing order");
      console.error(error);
      setCCError(`${error}`);
    }
  };

  return (
    <>
      <SEO title="Acclaimed Home Warranty" description="" />
      <NavBar />
      <main>
        {/** Banner */}
        <div className="w-full" style={{ overflow: "visible" }}>
          <Container maxW={["lg", "6xl"]} pt="12" position="relative">
            <HStack
              w="full"
              justifyContent="center"
              alignItems="flex-end"
              mb="20px"
              mt="60px"
            >
              <Box
                w={{ base: "240px", lg: "300px" }}
                h={{ base: "200px", lg: "260px" }}
                display={{ sm: "none", lg: "block" }}
                background="#beeb9e"
                borderRadius="100%"
                position="absolute"
                bottom="-60px"
                left="-90px"
                zIndex="-100"
              ></Box>
              <Box w="300px" position="absolute" bottom="-25px" left="120px">
                <StaticImage
                  src="../images/norman-shopping-cart.png"
                  alt="AcclaimedHW contact us"
                />
              </Box>
              <VStack gap="0" lineHeight="0.8" pb="20px">
                <Text
                  fontFamily="'League Spartan', sans-serif"
                  textAlign="center"
                  fontSize={{ base: "3xl", md: "5xl", lg: "7xl" }}
                  textColor="#000"
                  mb="20px"
                >
                  CHECKOUT
                </Text>
                <Text
                  textAlign="center"
                  fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
                  textColor="#000"
                  fontStyle="italic"
                >
                  Thank you for your order!
                </Text>
              </VStack>
              <Box
                w={{ base: "200px", lg: "260px" }}
                h={{ base: "200px", lg: "260px" }}
                display={{ sm: "none", lg: "block" }}
                background="#beeb9e"
                borderRadius="100%"
                position="absolute"
                bottom="40px"
                right="-15px"
                zIndex="-100"
              ></Box>
            </HStack>
          </Container>
          <Box w="full" h="12" background="#01a1ba" py="2"></Box>
        </div>
        <Container maxW={["lg", "6xl"]} mt="20px" mb="40px">
          <CouponForm />
          <Form<any, typeof masterSchema>
            formRef={orderRef}
            onSubmit={onSubmitOrder}
            schema={masterSchema}
          >
            {({ register, formState, setValue }) => (
              <>
                <SimpleGrid columns={[1, 2]} spacing="10px" marginY="20px">
                  <VStack spacing="10px" alignItems="flex-start" padding="15px">
                    <HStack w="full" alignItems="baseline">
                      <InputField
                        label="First name"
                        // value='Seller'
                        isRequired
                        onChange={validateForm}
                        placeholder="Homeowner name"
                        registration={register("first_name")}
                        error={formState.errors["first_name"]}
                        className="bg-lightgray input-red-placeholder"
                      />
                      <InputField
                        label="Last name"
                        // value='One'
                        onChange={validateForm}
                        isRequired
                        placeholder="Homeowner name"
                        registration={register("last_name")}
                        error={formState.errors["last_name"]}
                        className="bg-lightgray input-red-placeholder"
                      />
                    </HStack>
                    <InputField
                      label="Phone Number"
                      // value='1112080402'
                      isRequired
                      onChange={validateForm}
                      registration={register("phone")}
                      error={formState.errors["phone"]}
                      placeholder="Homeowner number"
                      className="bg-lightgray input-red-placeholder"
                    />
                    <InputField
                      label="Email address"
                      // value='seller1@bidboxpro.com'
                      isRequired
                      type="email"
                      onChange={validateForm}
                      registration={register("email")}
                      error={formState.errors["email"]}
                      placeholder="Homeowner email"
                      className="bg-lightgray input-red-placeholder"
                    />
                  </VStack>
                  <VStack spacing="10px" alignItems="flex-start" padding="15px">
                    <FormControl>
                      <h3>Warrantied address</h3>
                      <Autocomplete
                        ref={ref}
                        apiKey="AIzaSyBmaJ-SMSiwPJCpwfIH7MjcG0DO9kzQAbs"
                        onPlaceSelected={(place) => {
                          let address1: string = "";
                          let postcode: string = "";
                          let state: string = "";
                          let city: string = "";

                          for (const component of place.address_components) {
                            const componentType = component.types[0];

                            switch (componentType) {
                              case "street_number": {
                                address1 = `${component.long_name} ${address1}`;
                                break;
                              }
                              case "route": {
                                address1 += component.short_name;
                                break;
                              }

                              case "locality": {
                                city = component.long_name;
                                break;
                              }

                              case "administrative_area_level_1": {
                                state = component.short_name;
                                break;
                              }

                              case "postal_code": {
                                postcode = component.long_name;
                                break;
                              }
                            }
                          }

                          setWarranty({
                            ...warrantyInfo,
                            city: city,
                            address: address1,
                            zip: postcode,
                            state: state,
                            lat: place.geometry.location.lat,
                            lon: place.geometry.location.lng,
                            place_id: place.place_id,
                          });
                          setValue("warranty_state", state, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                          ref.current.value = address1;
                          validateForm(place);
                        }}
                        options={{
                          types: ["address"],
                          componentRestrictions: { country: "us" },
                        }}
                        className="bg-lightgray"
                        style={{
                          width: "100%",
                          outline: "2px solid transparent",
                          outlineOffset: "2px",
                          borderRadius: "0.375rem",
                          paddingInlineEnd: "1rem",
                          paddingInlineStart: "1rem",
                          fontSize: "1rem",
                          border: "1px solid",
                          borderColor: "inherit",
                          height: "2.5rem",
                        }}
                        libraries={["places"]}
                        required
                        aria-required="true"
                      />
                    </FormControl>
                    <FormControl>
                      <h3>City</h3>
                      <Input
                        className="bg-lightgray"
                        name="warranty_city"
                        type="text"
                        isRequired
                        value={warrantyInfo.city}
                        autoComplete="false"
                        onChange={(e) =>
                          setWarranty({ ...warrantyInfo, city: e.target.value })
                        }
                      />
                    </FormControl>
                    <HStack w="full">
                      <FormControl>
                        <h3> Add State </h3>
                        <Select
                          width="100%"
                          className="bg-lightgray"
                          onChange={(e) =>
                            setWarranty({
                              ...warrantyInfo,
                              state: e.target.value,
                            })
                          }
                          isRequired
                          value={warrantyInfo.state}
                        >
                          {usaStates.map((state) => (
                            <option value={state.abbreviation} key={state.name}>
                              {state.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl>
                        <h3>Zip Code</h3>
                        <Input
                          className="bg-lightgray"
                          name="warranty_zip"
                          type="text"
                          isRequired
                          value={warrantyInfo.zip}
                          autoComplete={false}
                          _autofill="false"
                          onChange={(e) =>
                            setWarranty({
                              ...warrantyInfo,
                              zip: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </HStack>
                  </VStack>
                  {!isHomeownerOrder && (
                    <>
                      <VStack p="15px">
                        <FormLabel
                          fontSize="2xl"
                          alignItems="center"
                          alignSelf="baseline"
                          mt="4"
                          display="flex"
                          gap="2"
                          cursor="pointer"
                        >
                          <Checkbox
                            defaultChecked={isBuyerAgentEnabled}
                            checked={isBuyerAgentEnabled}
                            /* always required, per Amanda. Otherwise, uncommenting the following line, and
                                                           removing the 'hidden' directive, will allow the requirement to be bypassed by
                                                           un-checking the Form box. The box will be pre-checked only for Buyer txns.
                                                        onChange={(e) => setBuyerAgentEnabled(e.target.checked)} */
                            hidden
                          />
                          Buyer's Agent
                        </FormLabel>
                        <HStack w="full" alignItems="baseline">
                          <InputField
                            label="Name"
                            isRequired
                            registration={register("buyer_agent_name")}
                            error={formState.errors["buyer_agent_name"]}
                            className="bg-lightgray"
                            disabled={!isBuyerAgentEnabled}
                          />
                          <InputField
                            label="Brokerage"
                            isRequired
                            placeholder="Buyer Agent Brokerage"
                            registration={register("buyer_agent_brokerage")}
                            error={formState.errors["buyer_agent_brokerage"]}
                            className="bg-lightgray"
                            disabled={!isBuyerAgentEnabled}
                          />
                        </HStack>
                        <HStack w="full" alignItems="baseline">
                          <InputField
                            label="Phone"
                            isRequired
                            placeholder="Buyer Agent phone"
                            registration={register("buyer_agent_phone")}
                            error={formState.errors["buyer_agent_phone"]}
                            className="bg-lightgray"
                            disabled={!isBuyerAgentEnabled}
                          />
                          <InputField
                            label="Email"
                            isRequired
                            type="email"
                            placeholder="Buyer Agent email"
                            registration={register("buyer_agent_email")}
                            error={formState.errors["buyer_agent_email"]}
                            className="bg-lightgray"
                            disabled={!isBuyerAgentEnabled}
                          />
                        </HStack>
                        <FormLabel
                          fontSize="2xl"
                          alignItems="center"
                          alignSelf="baseline"
                          mt="4"
                          display="flex"
                          gap="2"
                          cursor="pointer"
                        >
                          <Checkbox
                            defaultChecked={isSellerAgentEnabled}
                            checked={isSellerAgentEnabled}
                            onChange={(e) =>
                              setSellerAgentEnabled(e.target.checked)
                            }
                          />
                          Seller's Agent
                        </FormLabel>
                        <HStack w="full" alignItems="baseline">
                          <InputField
                            label="Name"
                            isRequired
                            registration={register("seller_agent_name")}
                            error={formState.errors["seller_agent_name"]}
                            className="bg-lightgray"
                            disabled={!isSellerAgentEnabled}
                          />
                          <InputField
                            label="Brokerage"
                            isRequired
                            placeholder="Seller Agent Brokerage"
                            registration={register("seller_agent_brokerage")}
                            error={formState.errors["seller_agent_brokerage"]}
                            className="bg-lightgray"
                            disabled={!isSellerAgentEnabled}
                          />
                        </HStack>
                        <HStack w="full" alignItems="baseline">
                          <InputField
                            label="Phone"
                            isRequired
                            placeholder="Seller Agent phone"
                            registration={register("seller_agent_phone")}
                            error={formState.errors["seller_agent_phone"]}
                            className="bg-lightgray"
                            disabled={!isSellerAgentEnabled}
                          />
                          <InputField
                            label="Email"
                            isRequired
                            type="email"
                            placeholder="Seller Agent email"
                            registration={register("seller_agent_email")}
                            error={formState.errors["seller_agent_email"]}
                            className="bg-lightgray"
                            disabled={!isSellerAgentEnabled}
                          />
                        </HStack>
                      </VStack>
                      <VStack p="15px">
                        <FormLabel
                          fontSize="2xl"
                          alignItems="center"
                          alignSelf="baseline"
                          mt="4"
                          display="flex"
                          gap="2"
                          cursor="pointer"
                        >
                          <Checkbox
                            defaultChecked={isEscrowEnabled}
                            checked={isEscrowEnabled}
                            onChange={(e) => setEscrowEnabled(e.target.checked)}
                          />
                          Add Escrow/Title
                        </FormLabel>
                        <HStack w="full" alignItems="baseline">
                          <InputField
                            label="Officer Name"
                            isRequired
                            placeholder="Officer name"
                            registration={register("title_name")}
                            error={formState.errors["title_name"]}
                            className="bg-lightgray"
                            disabled={!isEscrowEnabled}
                          />
                          <InputField
                              label="Closing Company"
                              isRequired
                              registration={register("title_company")}
                              error={formState.errors["title_company"]}
                              className="bg-lightgray"
                              disabled={!isEscrowEnabled}
                          />
                        </HStack>
                        <HStack w="full" alignItems="baseline">
                          <InputField
                            label="Officer Phone"
                            isRequired
                            placeholder="Officer phone"
                            registration={register("title_phone")}
                            error={formState.errors["title_phone"]}
                            className="bg-lightgray"
                            disabled={!isEscrowEnabled}
                          />
                          <InputField
                              label="Officer Email"
                              isRequired
                              type="email"
                              placeholder="Officer Email"
                              registration={register("title_email")}
                              error={formState.errors["title_email"]}
                              className="bg-lightgray"
                              disabled={!isEscrowEnabled}
                          />
                        </HStack>
                        <FormLabel
                          fontSize="2xl"
                          alignItems="center"
                          alignSelf="baseline"
                          mt="4"
                          display="flex"
                          gap="2"
                          cursor="pointer"
                        >
                          <Checkbox
                            defaultChecked={isLenderEnabled}
                            checked={isLenderEnabled}
                            onChange={(e) => setLenderEnabled(e.target.checked)}
                          />
                          Add Lender
                        </FormLabel>
                        <HStack w="full" alignItems="baseline">
                          <InputField
                            label="Loan Officer Name"
                            isRequired
                            placeholder="Loan Officer name"
                            registration={register("lender_name")}
                            error={formState.errors["lender_name"]}
                            className="bg-lightgray"
                            disabled={!isLenderEnabled}
                          />
                          <InputField
                              label="Lending Company"
                              isRequired
                              registration={register("lender_company")}
                              error={formState.errors["lender_company"]}
                              className="bg-lightgray"
                              disabled={!isLenderEnabled}
                          />
                        </HStack>
                        <HStack w="full" alignItems="baseline">
                          <InputField
                            label="Loan Officer Phone"
                            isRequired
                            placeholder="Loan Officer phone"
                            registration={register("lender_phone")}
                            error={formState.errors["lender_phone"]}
                            className="bg-lightgray"
                            disabled={!isLenderEnabled}
                          />
                          <InputField
                              label="Loan Officer Email"
                              isRequired
                              type="email"
                              placeholder="Loan Officer email"
                              registration={register("lender_email")}
                              error={formState.errors["lender_email"]}
                              className="bg-lightgray"
                              disabled={!isLenderEnabled}
                          />
                        </HStack>
                        <FormLabel
                          fontSize="2xl"
                          alignItems="center"
                          alignSelf="baseline"
                          mt="4"
                          display="flex"
                          gap="2"
                          cursor="pointer"
                        >
                          <Checkbox
                            defaultChecked={isTCEnabled}
                            checked={isTCEnabled}
                            onChange={(e) => setTCEnabled(e.target.checked)}
                          />
                          Add Transaction Coordinator
                        </FormLabel>
                        <HStack w="full" alignItems="baseline">
                          <InputField
                            label="Name"
                            isRequired
                            placeholder="TC name"
                            registration={register("tc_name")}
                            error={formState.errors["tc_name"]}
                            className="bg-lightgray"
                            disabled={!isTCEnabled}
                          />
                          <InputField
                              label="Company"
                              isRequired
                              registration={register("tc_company")}
                              error={formState.errors["tc_company"]}
                              className="bg-lightgray"
                              disabled={!isTCEnabled}
                          />
                        </HStack>
                        <HStack w="full" alignItems="baseline">
                          <InputField
                            label="Phone"
                            isRequired
                            placeholder="TC phone"
                            registration={register("tc_phone")}
                            error={formState.errors["tc_phone"]}
                            className="bg-lightgray"
                            disabled={!isTCEnabled}
                          />
                          <InputField
                              label="Email"
                              isRequired
                              type="email"
                              placeholder="TC email"
                              registration={register("tc_email")}
                              error={formState.errors["tc_email"]}
                              className="bg-lightgray"
                              disabled={!isTCEnabled}
                          />
                        </HStack>
                      </VStack>
                      <VStack>
                        <br />
                        <Text fontSize="2xl" alignSelf="baseline" mt="2">
                          Additional Information Order notes(optional)
                        </Text>
                        <TextAreaField
                          placeholder="Notes about your order, e.g. special notes for delivery"
                          registration={register("order_notes")}
                          error={formState.errors["order_notes"]}
                          className="bg-lightgray"
                          rows={4}
                        />
                      </VStack>
                    </>
                  )}
                </SimpleGrid>
                <Box padding="15px">
                  <Text>Your Order</Text>
                  <TableContainer
                    border="1px solid rgba(0,0,0,.1)"
                    borderRadius="10px"
                  >
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Product</Th>
                          <Th isNumeric fontWeight="bold" color="black">
                            Subtotal
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {carts.map((cart) => (
                          <Tr fontSize="sm" key={cart.system}>
                            <Td>
                              {cart.qty != 0 ? cart.qty : 1}x {cart.system}
                            </Td>
                            <Td isNumeric>
                              $
                              {(
                                (isAnnual ? cart.cost : cart.cost_monthly) *
                                (cart.qty != 0 ? cart.qty : 1)
                              ).toFixed(2)}
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                      <Tfoot fontWeight="bold" color="black">
                        <Tr>
                          <Th>Subtotal</Th>
                          <Th isNumeric textTransform="lowercase">
                            ${totalPrice.toFixed(2)}
                          </Th>
                        </Tr>
                        <Tr>
                          <Th>Tax (n/a)</Th>
                          <Th isNumeric textTransform="lowercase">
                            $0.00
                          </Th>
                        </Tr>
                        <Tr>
                          <Th fontWeight="bold" color="black">
                            Total
                          </Th>
                          <Th isNumeric fontWeight="bold" color="black">
                            ${totalPrice.toFixed(2)}{" "}
                            {multipleYears && (
                              <>
                                {isAnnual
                                  ? multipleYears + " yrs"
                                  : "/mo Subscription"}
                              </>
                            )}{" "}
                          </Th>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </TableContainer>
                </Box>
                <Box w="100%">
                  <Flex justifyContent="space-between" marginTop="20px">
                    <Text fontSize="sm" flex={1}>
                      Your personal data will be used to process your order, and
                      to support your experience throughout this website
                    </Text>
                    <Button
                      isLoading={isLoading}
                      type="submit"
                      background="#a46497"
                      color="white"
                      onClick={validateForm}
                      height={affiliateOrderH}
                      marginRight="1"
                      ref={orderButtonRef}
                    >
                      Place order
                    </Button>
                  </Flex>
                </Box>
              </>
            )}
          </Form>
          {isHomeownerOrder && (
            <Box w="100%">
              <Flex justifyContent="space-between" marginTop="20px">
                <div flex={1}></div>
                {/*<Text fontSize="sm" flex={1}>*/}
                {/*    Your personal data will be used to process your order,*/}
                {/*    support your experience throughout this website*/}
                {/*</Text>*/}
                {/*<Button onClick={manualCC}>Manual</Button>*/}
                <form
                  id="paymentForm"
                  method="POST"
                  action="https://YourServer/PathToExistingPaymentProcessingScript"
                >
                  <input type="hidden" name="dataValue" id="dataValue" />
                  <input
                    type="hidden"
                    name="dataDescriptor"
                    id="dataDescriptor"
                  />
                  <Button
                    isLoading={isLoading}
                    disabled={!orderValid}
                    type="button"
                    className="AcceptUI"
                    background="#a46497"
                    color="white"
                    data-billingAddressOptions='{"show":true, "required":false}'
                    data-apiLoginID={BidBoxConfig.AUTHNET_ID}
                    data-clientKey={BidBoxConfig.AUTHNET_CLIENT_KEY}
                    data-acceptUIFormBtnTxt="Submit"
                    data-acceptUIFormHeaderTxt="Card Information"
                    data-responseHandler="authnetresp"
                  >
                    Purchase
                  </Button>
                </form>
              </Flex>
              {ccError !== "" && (
                <Flex justifyContent="space-between">
                  <Text></Text>
                  <Text
                    background="#7d2727"
                    color="white"
                    mt="10px"
                    padding={"5px"}
                  >
                    <b>ERROR: {ccError}</b>
                  </Text>
                </Flex>
              )}
            </Box>
          )}
        </Container>
      </main>
      <Footer />
    </>
  );
};

const CouponForm = () => {
  const [isShowCoupon, toggleShowCoupon] = useState(false);
  return (
    <div>
      {/*<HStack*/}
      {/*    borderTop="3px solid #1e85be"*/}
      {/*    padding="6px 10px"*/}
      {/*    spacing="2"*/}
      {/*    backgroundColor="#f7f6f7"*/}
      {/*    fontSize="sm"*/}
      {/*    marginBottom="20px"*/}
      {/*>*/}
      {/*    <AiFillNotification color="#1e85be"/>*/}
      {/*    <span>Have a coupon?</span>*/}
      {/*    <Button*/}
      {/*        fontSize="sm"*/}
      {/*        color="#6fa553"*/}
      {/*        type="button"*/}
      {/*        variant="link"*/}
      {/*        onClick={() => toggleShowCoupon(!isShowCoupon)}*/}
      {/*    >*/}
      {/*        Click here to enter your code*/}
      {/*    </Button>*/}
      {/*</HStack>*/}

      {isShowCoupon && (
        <Form<CouponCode, typeof couponSchema>
          onSubmit={onSubmitCoupon}
          schema={couponSchema}
        >
          {({ register, formState }) => (
            <VStack
              border="1px solid #d3ced2"
              borderRadius="5px"
              textAlign="left"
              padding="20px 10px"
              spacing="10px"
              alignItems="flex-start"
            >
              <Text fontSize="sm">
                If you have a coupon code, please apply it below.
              </Text>
              <HStack w="full" alignItems="baseline">
                <Box w="full">
                  <InputField
                    isRequired
                    placeholder="Coupon code"
                    error={formState.errors["code"]}
                    registration={register("code")}
                  />
                </Box>
                <Button type="submit" padding="10px" onClick={() => {}}>
                  {" "}
                  Apply coupon
                </Button>
              </HStack>
            </VStack>
          )}
        </Form>
      )}
    </div>
  );
};

export default CheckoutPage;
